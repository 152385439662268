<template>
  <div id="app">
    <main-nav></main-nav>
    <main>
      <router-view />
    </main>
  </div>
</template>
<script>
import MainNav from "@/views/layouts/MainNav";

export default {
  components: {
    MainNav,
  },
};
</script>

<style>
</style>
