<template>
  <div class="fixed w-full flex justify-center items-center text-white p-4">
    <div class="">
      <router-link
        v-if="$route.path != '/'"
        to="/"
        class="mx-8 uppercase font-bold"
        >Home</router-link
      >
      <router-link
        v-if="$route.path != '/erys'"
        to="/erys"
        class="mx-8 uppercase font-bold"
        >Erys</router-link
      >
      <router-link
        v-if="$route.path != '/friday'"
        to="/friday"
        class="mx-8 uppercase font-bold"
        >Friday</router-link
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>